// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBSIBTSfjkuJlX7vK46j22OcXpcnxBymCM",
  authDomain: "gechi-di-maremma.firebaseapp.com",
  databaseURL:
    "https://gechi-di-maremma-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "gechi-di-maremma",
  storageBucket: "gechi-di-maremma.appspot.com",
  messagingSenderId: "926055022493",
  appId: "1:926055022493:web:c393468b513011b06f7974",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export default app;
