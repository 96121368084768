import { redirect } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../shared/firebase";

export function getAuthToken() {
  if (getExpire() < 0) {
    localStorage.removeItem("token");
    return null;
  }

  return localStorage.getItem("token");
}

export function tokenLoader() {
  return getAuthToken();
}

export function getExpire() {
  const expireDate = new Date(localStorage.getItem("expire"));
  const now = new Date();
  return expireDate.getTime() - now.getTime();
}

export function Login(authData: { email: ""; password: "" }) {
  return signInWithEmailAndPassword(auth, authData.email, authData.password);
}

export function setToken(token) {
  localStorage.setItem("token", token);

  const expire = new Date();
  expire.setHours(expire.getHours() + 1);

  localStorage.setItem("expire", expire.toISOString());
}

export function checkAuthLoader() {
  const token = getAuthToken();
  if (!token) {
    return redirect("/login");
  }
  return null;
}

export function Logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("expire");
}
