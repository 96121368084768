import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./layout/navbar";
import Footer from "./layout/footer";
import HeaderNavigation from "./layout/HeaderNavigationPage";

export default function Root() {
  const route = useLocation();
  const routeWithoutHeader = ["/", "/post/"];
  return (
    <>
      <Navbar />
      {!routeWithoutHeader.find(
        (i) => i === route.pathname.substring(0, 6)
      ) && <HeaderNavigation />}
      <Outlet />
      <Footer />
    </>
  );
}
