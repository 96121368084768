import { Link } from "react-router-dom";
import { motion, useTransform, useScroll } from "framer-motion";
import classes from "./navbar.module.css";
import NavItem from "../components/NavItemComponent";
import geco from "../shared/svg/geco.svg";
export default function Navbar() {
  const { scrollY } = useScroll();
  const navColor = useTransform(scrollY, [0, 10], ["transparent", "#3f3f3f"]);
  return (
    <motion.nav
      className={`navbar navbar-expand-lg fixed-top ${classes.navbar}`}
      style={{ backgroundColor: navColor }}
    >
      <div className="container-fluid">
        <motion.span whileHover={{ scale: 1.1 }}>
          <Link
            className={`${classes["navbar-brand"]}`}
            to="/"
            style={{ textDecoration: "none" }}
          >
            <img
              src={geco}
              alt="logo geco"
              className={classes["navbar-image"]}
            />
            Gechi di Maremma
          </Link>
        </motion.span>
        <button
          className={`navbar-toggler ${classes["navbar-toogle-button"]}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${classes["navbar-collapse"]}`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
            <NavItem className="nav-link text-white" to="/" ariaCurrent="page">
              Home
            </NavItem>
            {/* <NavItem
              className="nav-link text-white"
              to="/chi-siamo"
              ariaCurrent="page"
            >
              Chi Siamo
            </NavItem> */}
            <NavItem className="nav-link text-white" to="/eventi">
              Eventi
            </NavItem>
            <NavItem className="nav-link text-white" to="/foto">
              Foto
            </NavItem>
            <NavItem
              className="nav-link text-white"
              to="https://www.alpineteam.it/"
              target="_blank"
            >
              Relazioni
            </NavItem>
            <NavItem className="nav-link text-white" to="/contatti">
              Contattaci
            </NavItem>
          </ul>
          {/* <form className="d-flex" role="search">
            <MyInput
              className="me-2"
              type="search"
              placeholder="Search"
              ariaLabel="Search"
            />
            <MyButton
              onClick={() => {
                console.log("clicked");
              }}
            >
              Cerca
            </MyButton>
          </form> */}
        </div>
      </div>
    </motion.nav>
  );
}
