import { Link } from "react-router-dom";
import classes from "./footer.module.css";
import logo from "../shared/images/pfsoftware.png";
export default function Footer() {
  return (
    <footer className={classes.footer}>
      <div className="container">
        <div className="row ms-2">
          <div className="col-sm-4">
            <h3>Contatti</h3>
            <p>
              <Link to="tel: +39 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="green"
                  className="bi bi-telephone me-2 mb-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                </svg>
                Chiamaci
              </Link>
            </p>
            <p>
              <Link to="mailto: gechidimaremma@gmail.com">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="blue"
                  className="bi bi-envelope me-2 mb-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                </svg>
                gechidimaremma@gmail.com
              </Link>
            </p>
          </div>
          <div className="col-sm-4">
            <h3>Dove siamo</h3>
            <p>Grosseto, 58100</p>
            <p>
              <Link
                to="https://www.google.it/maps/place/58100+Grosseto+GR/@42.7679373,11.0862861,13z/data=!3m1!4b1!4m6!3m5!1s0x1329b0f4c7a57679:0x4837c0687f14d124!8m2!3d42.7635254!4d11.1123634!16zL20vMDQyMG05?entry=ttu"
                target="blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="red"
                  className="bi bi-geo-alt me-2 mb-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
                Apri la mappa
              </Link>
            </p>
          </div>
          <div className="col-sm-4">
            <h3>Informazioni</h3>
            <p>
              <a
                href="https://www.iubenda.com/privacy-policy/95438086"
                className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                title="Privacy Policy "
              >
                Privacy Policy
              </a>
              <script type="text/javascript">
                {`(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);`}
              </script>
            </p>
            <p>
              <a
                href="https://www.iubenda.com/privacy-policy/95438086/cookie-policy"
                className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                title="Cookie Policy "
              >
                Cookie Policy
              </a>
              <script type="text/javascript">
                {`(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);`}
              </script>
            </p>
            <Link to="https://www.pfsoftware.it/" target="blank">
              Powered by <img src={logo} alt="pf software" /> PF Software
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
