import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import { Get } from "../api/dbFunctions";
import PostList from "../components/post/PostListComponent";
import Header from "../layout/HeaderComponent";
import Loading from "../components/LoadingComponent";
import Error from "../components/ErrorComponent";
import { blogPost } from "../classes/blogPost";
import Presentation from "../components/PresentationComponent";
export default function Home() {
  const data: any = useLoaderData();
  return (
    <>
      <Header />
      <Suspense fallback={<Loading />}>
        <Await resolve={data?.post} errorElement={<Error />}>
          {(loaded) => <PostList postList={loaded} />}
        </Await>
      </Suspense>
      <Presentation />
    </>
  );
}

async function loadElements() {
  const response: blogPost[] = await Get("post");
  if (response) {
    return response;
  }
  return null;
}

export async function loader() {
  return defer({
    post: loadElements(),
  });
}
