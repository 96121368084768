import { Link } from "react-router-dom";
import { motion, useTransform, useScroll } from "framer-motion";
import classes from "./NavItemComponent.module.css";
export default function NavItem({
  children = undefined,
  className = undefined,
  to = undefined,
  ariaCurrent = undefined,
  target = undefined,
}) {
  const { scrollY } = useScroll();
  const textScale = useTransform(scrollY, [0, 10], [1, 0.8]);
  return (
    <motion.li
      className={`nav-item ${classes["item-text"]}`}
      whileHover={{
        scale: 1.1,
      }}
      style={{ scale: textScale }}
    >
      <Link
        className={`nav-link text-white ${className}`}
        aria-current={ariaCurrent}
        to={to}
        target={target}
      >
        {children}
      </Link>
    </motion.li>
  );
}
