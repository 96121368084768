import { useRouteError } from "react-router-dom";
import errorImage from "../shared/images/error_image.jpg";
import Navbar from "../layout/navbar";
import HeaderNavigation from "../layout/HeaderNavigationPage";
export default function Error() {
  var message = "An error has occured!";
  const error: any = useRouteError();
  console.log(error);
  if (error.status === 404) {
    message = "OPS! This page does not exists.";
  }
  return (
    <>
      <Navbar />
      <HeaderNavigation />
      <div className="container text-center">
        <h1>{message}</h1>
        <img src={errorImage} alt="" />
      </div>
    </>
  );
}
