import { motion, useTransform, useScroll } from "framer-motion";
import classes from "./HeaderComponent.module.css";
import logo from "../shared/images/logo_gechi.png";
export default function Header() {
  const { scrollY } = useScroll();
  const divY = useTransform(scrollY, [0, 200], [0, 110]);
  const imageY = useTransform(scrollY, [0, 200], [0, 70]);
  const imageScale = useTransform(scrollY, [0, 200], [1, 0.9]);
  const textScale = useTransform(scrollY, [0, 200], [1, 0.7]);
  const textY = useTransform(scrollY, [0, 100], [0, 10]);
  return (
    <header className={classes.header}>
      <motion.div className="container" style={{ y: divY, scale: imageScale }}>
        <div className={`${classes["header-text"]} text-uppercase`}>
          <motion.img
            src={logo}
            alt="Gechi di Maremma"
            style={{
              height: "200px",
              width: "auto",
              y: imageY,
              scale: imageScale,
            }}
          />
        </div>
        <div className={`${classes["header-secondary-text"]}`}>
          <motion.p style={{ scale: textScale, y: textY }}>
            Gruppo Alpinistico della Maremma
          </motion.p>
        </div>
      </motion.div>
    </header>
  );
}
