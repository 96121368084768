import "./Loading.css";

export default function Loading() {
  return (
    <div className="container text-center">
      <div className="loadingio-spinner-eclipse-ocgahwhik8k">
        <div className="ldio-mdlwbzvnsrm">
          <div></div>
        </div>
      </div>
    </div>
  );
}
