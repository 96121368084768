import { getAuthToken } from "../auth/auth";
import {
  deleteObject,
  getBlob,
  getDownloadURL,
  listAll,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../shared/firebase";
import { Image } from "../classes/image";

export async function Insert(object, table) {
  const token = getAuthToken();
  const res = await fetch(
    `https://gechi-di-maremma-default-rtdb.europe-west1.firebasedatabase.app/${table}.json?auth=${token}`,
    {
      method: "POST",
      body: JSON.stringify(object),
      headers: { "Content-Type": "application/json" },
    }
  );
  const response = await res.json();
  if (res.ok) {
    return response.name;
  }
}

export async function Put(id, object, table) {
  const token = getAuthToken();
  const res = await fetch(
    `https://gechi-di-maremma-default-rtdb.europe-west1.firebasedatabase.app/${table}/${id}.json?auth=${token}`,
    {
      method: "PUT",
      body: JSON.stringify(object),
      headers: { "Content-Type": "application/json" },
    }
  );
  if (res.ok) {
    return true;
  }
}

export async function Delete(id, table) {
  const token = getAuthToken();
  const res = await fetch(
    `https://gechi-di-maremma-default-rtdb.europe-west1.firebasedatabase.app/${table}/${id}.json?auth=${token}`,
    {
      method: "DELETE",
    }
  );
  if (res.ok) {
    return true;
  }
}

export async function Get(table) {
  const res = await fetch(
    `https://gechi-di-maremma-default-rtdb.europe-west1.firebasedatabase.app/${table}.json`
  );
  if (res.ok) {
    const data = await res.json();
    if (data) {
      return Object.keys(data).map((id) => ({
        id: id,
        ...data[id],
      }));
    }
  }
}

export async function GetByID(table, id) {
  const res = await fetch(
    `https://gechi-di-maremma-default-rtdb.europe-west1.firebasedatabase.app/${table}/${id}.json`
  );
  if (res.ok) {
    const data = await res.json();
    if (data) {
      return {
        id: id,
        ...data,
      };
    }
  }
}

//#region IMAGE

export const getImage = async (ID: string, path: string) => {
  const stRef = storageRef(storage, `${path}/${ID}`);
  const imagesUrl: string[] = [];
  const imageList = await listAll(stRef);

  for (const item of imageList.items) {
    const url = await getDownloadURL(item);
    imagesUrl.push(url);
  }

  return imagesUrl;
};

export const getImagesFile = async (
  ID: string,
  path: string
): Promise<Image> => {
  const stRef = storageRef(storage, `${path}/${ID}`);
  const imageList = await listAll(stRef);
  const BLOB: Blob[] = [];
  const URL: string[] = [];
  for (const item of imageList.items) {
    const file = await getBlob(item);
    const url = await getDownloadURL(item);
    BLOB.push(file);
    URL.push(url);
  }
  return { URL: URL, BLOB: BLOB };
};

export async function uploadImages(
  images: Blob[],
  index: string,
  folderName: string
) {
  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    const storeRef = storageRef(
      storage,
      `${folderName}/${index}/image_${i}.jpg`
    );
    const arrayBuffer = await image.arrayBuffer();
    await uploadBytes(storeRef, arrayBuffer);
  }
}

export async function deleteImages(ID, folderName) {
  const storeRef = storageRef(storage, `${folderName}/${ID}/`);
  listAll(storeRef)
    .then((result) => {
      const deletePromises = result.items.map((fileRef) => {
        return deleteObject(fileRef);
      });
      return Promise.all(deletePromises);
    })
    .then(() => {
      return;
    });
}

//#endregion
