import { Await, Link } from "react-router-dom";
import { motion } from "framer-motion";
import classes from "./PostComponent.module.css";
import { getImage } from "../../api/dbFunctions";
import { Suspense } from "react";
import Loading from "../LoadingComponent";
export default function Post({ post }) {
  const images = getImage(post.id, "post");
  return (
    <motion.div
      className={`mt-2 ${classes["post-preview"]}`}
      whileHover={{ scale: 1.1, y: 10 }}
    >
      <Link to={`/post/${post.id}`}>
        <Suspense fallback={<Loading />}>
          <Await resolve={images}>
            {(resolve) => (
              <div className="row">
                <div className="col-sm-4">
                  <img
                    src={resolve[0]}
                    alt=""
                    className={classes["post-image"]}
                  />
                </div>
                <div className="col-sm-8">
                  <h2 className={classes["post-title"]}>{post.title}</h2>
                </div>
              </div>
            )}
          </Await>
        </Suspense>
        <h3 className={classes["post-subtitle"]}>{post.description}</h3>
      </Link>
      {/* <p className={classes["post-meta"]}>
        Posted by Start Bootstrap on September 24, 2023
      </p> */}
    </motion.div>
  );
}
