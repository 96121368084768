import classes from "./PresentationComponent.module.css";
import image from "../shared/images/homePage.avif";
export default function Presentation() {
  return (
    <>
      <section className={`${classes["page-section"]} ${classes["clearfix"]}`}>
        <div className="container">
          <div className={classes.intro}>
            <img
              className={`${classes["intro-img"]} ${classes["img-fluid"]} mb-3 mb-lg-0 rounded`}
              src={image}
              alt="Arrampicata su roccia"
            />
            <div
              className={`${classes["intro-text"]} left-0 text-center ${classes["bg-faded"]} p-5 rounded`}
            >
              <h2 className={`${classes["section-heading"]} mb-4`}>
                <span className={`${classes["section-heading-upper"]} mb-3`}>
                  Benvenuti nel mondo verticale con i Gechi di Maremma!
                </span>
                <span className={classes["section-heading-lower"]}>
                  Club Alpino Italiano
                </span>
                <span className={classes["section-heading-info"]}>
                  Sezione Grosseto
                </span>
              </h2>
              <p>
                I Gechi di Maremma non sono solo un gruppo di appassionati di
                montagna, ma una famiglia unita dalla voglia di sfidare le
                verticalità e abbracciare l'essenza dell'avventura. Con sede a
                Grosseto, ci dedichiamo all'esplorazione delle migliori pareti
                della Toscana ma non solo!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={`${classes["page-section"]} ${classes["cta"]}`}>
        <div className="container">
          <div className="row">
            <div className="col-xl-9 mx-auto">
              <div
                className={`${classes["cta-inner"]} ${classes["bg-faded"]} text-center rounded`}
              >
                <h2 className={`${classes["section-heading"]} mb-4`}>
                  <span className={`${classes["section-heading-upper"]} mb-3`}>
                    Le nostre attività principali:
                  </span>
                </h2>
                <ul>
                  <li>
                    <p>
                      <strong>Arrampicata su roccia: </strong>Durante i weekend,
                      ma anche nei giorni lavorativi, organizziamo varie uscite
                      di arrampicata in falesia o vie sportive multitiro. Le
                      location più comuni sono Capo d'Uomo (Monte Argentario),
                      la falesia di Montecalvo (Ravi) e Poggio al Montone (Massa
                      Marittima)
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Alpinismo: </strong>Dalle Apuane, all'Appennino
                      fino alle Dolomiti, esploriamo le montagne più
                      affascinanti d' Italia passando per le più belle vie
                      Alpinistiche in arrampicata tradizionale.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Stage di Arrampicata: </strong>In collaborazione
                      con il Club Alpino Italiano, sezione di Grosseto,
                      organizziamo annualmente uno stage di arrampicata su
                      roccia della durata di 4 settimane. Il corso è rivolto a
                      tutti coloro vogliono approcciare per la prima alla roccia
                      volta, ma anche a chi ha già esperienze in arrampicata e
                      vuole ribadire i concetti di sicurezza e tecnica.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
