import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Suspense, lazy } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Root from "./Root";
import Home, { loader as postLoader } from "./pages/homePage";
import Loading from "./components/LoadingComponent";
import Error from "./components/ErrorComponent";

const EventPage = lazy(() => import("./pages/eventsPage"));
const AlbumPage = lazy(() => import("./pages/albumPage"));
const ContactPage = lazy(() => import("./pages/contactPage"));
const LoginPage = lazy(() => import("./admin/loginPage"));
const AdminPage = lazy(() => import("./admin/adminPage"));
const EditPage = lazy(() => import("./admin/editPage"));

const PostPageAdmin = lazy(() => import("./admin/postListPage"));
const AlbumPageAdmin = lazy(() => import("./admin/fotoListPage"));
const EventPageAdmin = lazy(() => import("./admin/eventListPage"));

const PostDetails = lazy(
  () => import("./components/post/PostDetailsComponent")
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      { path: "/", element: <Home />, loader: postLoader },
      {
        path: "eventi",
        element: (
          <Suspense fallback={<Loading />}>
            <EventPage />
          </Suspense>
        ),
        loader: () =>
          import("./pages/eventsPage").then((module) => module.loader()),
      },
      {
        path: "foto",
        element: (
          <Suspense fallback={<Loading />}>
            <AlbumPage />
          </Suspense>
        ),
        loader: () =>
          import("./pages/albumPage").then((module) => module.loader()),
      },
      {
        path: "contatti",
        element: (
          <Suspense fallback={<Loading />}>
            <ContactPage />
          </Suspense>
        ),
      },
      {
        path: "post/:id",
        element: <PostDetails />,
        loader: (id) =>
          import("./components/post/PostDetailsComponent").then((module) =>
            module.loader(id)
          ),
      },
      {
        path: "login",
        element: (
          <Suspense fallback={<Loading />}>
            <LoginPage />
          </Suspense>
        ),
        action: (request) =>
          import("./admin/loginPage").then((module) => module.action(request)),
      },
      {
        path: "admin",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Loading />}>
                <AdminPage />
              </Suspense>
            ),
          },
          {
            path: "post-list",
            element: (
              <Suspense fallback={<Loading />}>
                <PostPageAdmin />
              </Suspense>
            ),
            loader: () =>
              import("./admin/postListPage").then((module) => module.loader()),
          },
          {
            path: "event-list",
            element: (
              <Suspense>
                <EventPageAdmin />
              </Suspense>
            ),
            loader: () =>
              import("./admin/eventListPage").then((module) => module.loader()),
          },
          {
            path: "foto-list",
            element: (
              <Suspense fallback={<Loading />}>
                <AlbumPageAdmin />
              </Suspense>
            ),
            loader: () =>
              import("./admin/fotoListPage").then((module) => module.loader()),
          },
          {
            path: "new-item/:mode",
            element: (
              <Suspense fallback={<Loading />}>
                <EditPage />
              </Suspense>
            ),
          },
          {
            path: "edit-item/:mode/:id",
            element: (
              <Suspense fallback={<Loading />}>
                <EditPage />
              </Suspense>
            ),
          },
        ],
        loader: () =>
          import("./auth/auth").then((module) => module.checkAuthLoader()),
      },
    ],
  },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
